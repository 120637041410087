import { onUnmounted as Ft, getCurrentScope as Dt, onScopeDispose as Vt, unref as E, getCurrentInstance as He, watch as z, ref as A, defineComponent as ze, useAttrs as Mt, computed as U, onMounted as ct, onBeforeUnmount as We, nextTick as ie, toRef as Le, openBlock as I, createBlock as oe, Teleport as Bt, withDirectives as Pe, createElementBlock as ee, mergeProps as Z, withKeys as Lt, withModifiers as Xe, createCommentVNode as le, Transition as Ze, toHandlers as Je, withCtx as we, normalizeClass as Pt, normalizeStyle as At, vShow as Ae, createVNode as $e, renderSlot as pe, normalizeProps as dt, guardReactiveProps as Rt, createElementVNode as H, inject as xt, shallowReactive as Se, markRaw as Ne, reactive as Ke, Fragment as It, renderList as Qe, resolveDynamicComponent as Re, createSlots as jt, resolveComponent as Ge, toDisplayString as et } from "vue";
import { _ as vt } from "./_plugin-vue_export-helper-hUChTQA_.js";
import { V as $t } from "./VButton-lAIzfBSn.js";
function ft(t) {
  return Dt() ? (Vt(t), !0) : !1;
}
function pt(t) {
  return typeof t == "function" ? t() : E(t);
}
const Kt = typeof window < "u" && typeof document < "u";
typeof WorkerGlobalScope < "u" && globalThis instanceof WorkerGlobalScope;
const Gt = Object.prototype.toString, Ut = (t) => Gt.call(t) === "[object Object]", Ht = () => {
};
function zt(t) {
  return t || He();
}
function Wt(t, e) {
  zt(e) && Ft(t, e);
}
function bt(t) {
  var e;
  const n = pt(t);
  return (e = n == null ? void 0 : n.$el) != null ? e : n;
}
const _t = Kt ? window : void 0;
function X(...t) {
  let e, n, o, l;
  if (typeof t[0] == "string" || Array.isArray(t[0]) ? ([n, o, l] = t, e = _t) : [e, n, o, l] = t, !e)
    return Ht;
  Array.isArray(n) || (n = [n]), Array.isArray(o) || (o = [o]);
  const s = [], a = () => {
    s.forEach((b) => b()), s.length = 0;
  }, r = (b, v, g, w) => (b.addEventListener(v, g, w), () => b.removeEventListener(v, g, w)), i = z(
    () => [bt(e), pt(l)],
    ([b, v]) => {
      if (a(), !b)
        return;
      const g = Ut(v) ? { ...v } : v;
      s.push(
        ...n.flatMap((w) => o.map((D) => r(b, w, D, g)))
      );
    },
    { immediate: !0, flush: "post" }
  ), d = () => {
    i(), a();
  };
  return ft(d), d;
}
/*!
* tabbable 6.2.0
* @license MIT, https://github.com/focus-trap/tabbable/blob/master/LICENSE
*/
var mt = ["input:not([inert])", "select:not([inert])", "textarea:not([inert])", "a[href]:not([inert])", "button:not([inert])", "[tabindex]:not(slot):not([inert])", "audio[controls]:not([inert])", "video[controls]:not([inert])", '[contenteditable]:not([contenteditable="false"]):not([inert])', "details>summary:first-of-type:not([inert])", "details:not([inert])"], Oe = /* @__PURE__ */ mt.join(","), ht = typeof Element > "u", ae = ht ? function() {
} : Element.prototype.matches || Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector, Fe = !ht && Element.prototype.getRootNode ? function(t) {
  var e;
  return t == null || (e = t.getRootNode) === null || e === void 0 ? void 0 : e.call(t);
} : function(t) {
  return t == null ? void 0 : t.ownerDocument;
}, De = function t(e, n) {
  var o;
  n === void 0 && (n = !0);
  var l = e == null || (o = e.getAttribute) === null || o === void 0 ? void 0 : o.call(e, "inert"), s = l === "" || l === "true", a = s || n && e && t(e.parentNode);
  return a;
}, Yt = function(e) {
  var n, o = e == null || (n = e.getAttribute) === null || n === void 0 ? void 0 : n.call(e, "contenteditable");
  return o === "" || o === "true";
}, yt = function(e, n, o) {
  if (De(e))
    return [];
  var l = Array.prototype.slice.apply(e.querySelectorAll(Oe));
  return n && ae.call(e, Oe) && l.unshift(e), l = l.filter(o), l;
}, gt = function t(e, n, o) {
  for (var l = [], s = Array.from(e); s.length; ) {
    var a = s.shift();
    if (!De(a, !1))
      if (a.tagName === "SLOT") {
        var r = a.assignedElements(), i = r.length ? r : a.children, d = t(i, !0, o);
        o.flatten ? l.push.apply(l, d) : l.push({
          scopeParent: a,
          candidates: d
        });
      } else {
        var b = ae.call(a, Oe);
        b && o.filter(a) && (n || !e.includes(a)) && l.push(a);
        var v = a.shadowRoot || // check for an undisclosed shadow
        typeof o.getShadowRoot == "function" && o.getShadowRoot(a), g = !De(v, !1) && (!o.shadowRootFilter || o.shadowRootFilter(a));
        if (v && g) {
          var w = t(v === !0 ? a.children : v.children, !0, o);
          o.flatten ? l.push.apply(l, w) : l.push({
            scopeParent: a,
            candidates: w
          });
        } else
          s.unshift.apply(s, a.children);
      }
  }
  return l;
}, wt = function(e) {
  return !isNaN(parseInt(e.getAttribute("tabindex"), 10));
}, te = function(e) {
  if (!e)
    throw new Error("No node provided");
  return e.tabIndex < 0 && (/^(AUDIO|VIDEO|DETAILS)$/.test(e.tagName) || Yt(e)) && !wt(e) ? 0 : e.tabIndex;
}, qt = function(e, n) {
  var o = te(e);
  return o < 0 && n && !wt(e) ? 0 : o;
}, Xt = function(e, n) {
  return e.tabIndex === n.tabIndex ? e.documentOrder - n.documentOrder : e.tabIndex - n.tabIndex;
}, Tt = function(e) {
  return e.tagName === "INPUT";
}, Zt = function(e) {
  return Tt(e) && e.type === "hidden";
}, Jt = function(e) {
  var n = e.tagName === "DETAILS" && Array.prototype.slice.apply(e.children).some(function(o) {
    return o.tagName === "SUMMARY";
  });
  return n;
}, Qt = function(e, n) {
  for (var o = 0; o < e.length; o++)
    if (e[o].checked && e[o].form === n)
      return e[o];
}, en = function(e) {
  if (!e.name)
    return !0;
  var n = e.form || Fe(e), o = function(r) {
    return n.querySelectorAll('input[type="radio"][name="' + r + '"]');
  }, l;
  if (typeof window < "u" && typeof window.CSS < "u" && typeof window.CSS.escape == "function")
    l = o(window.CSS.escape(e.name));
  else
    try {
      l = o(e.name);
    } catch (a) {
      return console.error("Looks like you have a radio button with a name attribute containing invalid CSS selector characters and need the CSS.escape polyfill: %s", a.message), !1;
    }
  var s = Qt(l, e.form);
  return !s || s === e;
}, tn = function(e) {
  return Tt(e) && e.type === "radio";
}, nn = function(e) {
  return tn(e) && !en(e);
}, on = function(e) {
  var n, o = e && Fe(e), l = (n = o) === null || n === void 0 ? void 0 : n.host, s = !1;
  if (o && o !== e) {
    var a, r, i;
    for (s = !!((a = l) !== null && a !== void 0 && (r = a.ownerDocument) !== null && r !== void 0 && r.contains(l) || e != null && (i = e.ownerDocument) !== null && i !== void 0 && i.contains(e)); !s && l; ) {
      var d, b, v;
      o = Fe(l), l = (d = o) === null || d === void 0 ? void 0 : d.host, s = !!((b = l) !== null && b !== void 0 && (v = b.ownerDocument) !== null && v !== void 0 && v.contains(l));
    }
  }
  return s;
}, tt = function(e) {
  var n = e.getBoundingClientRect(), o = n.width, l = n.height;
  return o === 0 && l === 0;
}, an = function(e, n) {
  var o = n.displayCheck, l = n.getShadowRoot;
  if (getComputedStyle(e).visibility === "hidden")
    return !0;
  var s = ae.call(e, "details>summary:first-of-type"), a = s ? e.parentElement : e;
  if (ae.call(a, "details:not([open]) *"))
    return !0;
  if (!o || o === "full" || o === "legacy-full") {
    if (typeof l == "function") {
      for (var r = e; e; ) {
        var i = e.parentElement, d = Fe(e);
        if (i && !i.shadowRoot && l(i) === !0)
          return tt(e);
        e.assignedSlot ? e = e.assignedSlot : !i && d !== e.ownerDocument ? e = d.host : e = i;
      }
      e = r;
    }
    if (on(e))
      return !e.getClientRects().length;
    if (o !== "legacy-full")
      return !0;
  } else if (o === "non-zero-area")
    return tt(e);
  return !1;
}, rn = function(e) {
  if (/^(INPUT|BUTTON|SELECT|TEXTAREA)$/.test(e.tagName))
    for (var n = e.parentElement; n; ) {
      if (n.tagName === "FIELDSET" && n.disabled) {
        for (var o = 0; o < n.children.length; o++) {
          var l = n.children.item(o);
          if (l.tagName === "LEGEND")
            return ae.call(n, "fieldset[disabled] *") ? !0 : !l.contains(e);
        }
        return !0;
      }
      n = n.parentElement;
    }
  return !1;
}, Ve = function(e, n) {
  return !(n.disabled || // we must do an inert look up to filter out any elements inside an inert ancestor
  //  because we're limited in the type of selectors we can use in JSDom (see related
  //  note related to `candidateSelectors`)
  De(n) || Zt(n) || an(n, e) || // For a details element with a summary, the summary element gets the focus
  Jt(n) || rn(n));
}, Ue = function(e, n) {
  return !(nn(n) || te(n) < 0 || !Ve(e, n));
}, ln = function(e) {
  var n = parseInt(e.getAttribute("tabindex"), 10);
  return !!(isNaN(n) || n >= 0);
}, sn = function t(e) {
  var n = [], o = [];
  return e.forEach(function(l, s) {
    var a = !!l.scopeParent, r = a ? l.scopeParent : l, i = qt(r, a), d = a ? t(l.candidates) : r;
    i === 0 ? a ? n.push.apply(n, d) : n.push(r) : o.push({
      documentOrder: s,
      tabIndex: i,
      item: l,
      isScope: a,
      content: d
    });
  }), o.sort(Xt).reduce(function(l, s) {
    return s.isScope ? l.push.apply(l, s.content) : l.push(s.content), l;
  }, []).concat(n);
}, un = function(e, n) {
  n = n || {};
  var o;
  return n.getShadowRoot ? o = gt([e], n.includeContainer, {
    filter: Ue.bind(null, n),
    flatten: !1,
    getShadowRoot: n.getShadowRoot,
    shadowRootFilter: ln
  }) : o = yt(e, n.includeContainer, Ue.bind(null, n)), sn(o);
}, cn = function(e, n) {
  n = n || {};
  var o;
  return n.getShadowRoot ? o = gt([e], n.includeContainer, {
    filter: Ve.bind(null, n),
    flatten: !0,
    getShadowRoot: n.getShadowRoot
  }) : o = yt(e, n.includeContainer, Ve.bind(null, n)), o;
}, re = function(e, n) {
  if (n = n || {}, !e)
    throw new Error("No node provided");
  return ae.call(e, Oe) === !1 ? !1 : Ue(n, e);
}, dn = /* @__PURE__ */ mt.concat("iframe").join(","), xe = function(e, n) {
  if (n = n || {}, !e)
    throw new Error("No node provided");
  return ae.call(e, dn) === !1 ? !1 : Ve(n, e);
};
/*!
* focus-trap 7.5.4
* @license MIT, https://github.com/focus-trap/focus-trap/blob/master/LICENSE
*/
function nt(t, e) {
  var n = Object.keys(t);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(t);
    e && (o = o.filter(function(l) {
      return Object.getOwnPropertyDescriptor(t, l).enumerable;
    })), n.push.apply(n, o);
  }
  return n;
}
function ot(t) {
  for (var e = 1; e < arguments.length; e++) {
    var n = arguments[e] != null ? arguments[e] : {};
    e % 2 ? nt(Object(n), !0).forEach(function(o) {
      vn(t, o, n[o]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(t, Object.getOwnPropertyDescriptors(n)) : nt(Object(n)).forEach(function(o) {
      Object.defineProperty(t, o, Object.getOwnPropertyDescriptor(n, o));
    });
  }
  return t;
}
function vn(t, e, n) {
  return e = pn(e), e in t ? Object.defineProperty(t, e, {
    value: n,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : t[e] = n, t;
}
function fn(t, e) {
  if (typeof t != "object" || t === null)
    return t;
  var n = t[Symbol.toPrimitive];
  if (n !== void 0) {
    var o = n.call(t, e || "default");
    if (typeof o != "object")
      return o;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (e === "string" ? String : Number)(t);
}
function pn(t) {
  var e = fn(t, "string");
  return typeof e == "symbol" ? e : String(e);
}
var at = {
  activateTrap: function(e, n) {
    if (e.length > 0) {
      var o = e[e.length - 1];
      o !== n && o.pause();
    }
    var l = e.indexOf(n);
    l === -1 || e.splice(l, 1), e.push(n);
  },
  deactivateTrap: function(e, n) {
    var o = e.indexOf(n);
    o !== -1 && e.splice(o, 1), e.length > 0 && e[e.length - 1].unpause();
  }
}, bn = function(e) {
  return e.tagName && e.tagName.toLowerCase() === "input" && typeof e.select == "function";
}, mn = function(e) {
  return (e == null ? void 0 : e.key) === "Escape" || (e == null ? void 0 : e.key) === "Esc" || (e == null ? void 0 : e.keyCode) === 27;
}, be = function(e) {
  return (e == null ? void 0 : e.key) === "Tab" || (e == null ? void 0 : e.keyCode) === 9;
}, hn = function(e) {
  return be(e) && !e.shiftKey;
}, yn = function(e) {
  return be(e) && e.shiftKey;
}, rt = function(e) {
  return setTimeout(e, 0);
}, lt = function(e, n) {
  var o = -1;
  return e.every(function(l, s) {
    return n(l) ? (o = s, !1) : !0;
  }), o;
}, de = function(e) {
  for (var n = arguments.length, o = new Array(n > 1 ? n - 1 : 0), l = 1; l < n; l++)
    o[l - 1] = arguments[l];
  return typeof e == "function" ? e.apply(void 0, o) : e;
}, Ee = function(e) {
  return e.target.shadowRoot && typeof e.composedPath == "function" ? e.composedPath()[0] : e.target;
}, gn = [], wn = function(e, n) {
  var o = (n == null ? void 0 : n.document) || document, l = (n == null ? void 0 : n.trapStack) || gn, s = ot({
    returnFocusOnDeactivate: !0,
    escapeDeactivates: !0,
    delayInitialFocus: !0,
    isKeyForward: hn,
    isKeyBackward: yn
  }, n), a = {
    // containers given to createFocusTrap()
    // @type {Array<HTMLElement>}
    containers: [],
    // list of objects identifying tabbable nodes in `containers` in the trap
    // NOTE: it's possible that a group has no tabbable nodes if nodes get removed while the trap
    //  is active, but the trap should never get to a state where there isn't at least one group
    //  with at least one tabbable node in it (that would lead to an error condition that would
    //  result in an error being thrown)
    // @type {Array<{
    //   container: HTMLElement,
    //   tabbableNodes: Array<HTMLElement>, // empty if none
    //   focusableNodes: Array<HTMLElement>, // empty if none
    //   posTabIndexesFound: boolean,
    //   firstTabbableNode: HTMLElement|undefined,
    //   lastTabbableNode: HTMLElement|undefined,
    //   firstDomTabbableNode: HTMLElement|undefined,
    //   lastDomTabbableNode: HTMLElement|undefined,
    //   nextTabbableNode: (node: HTMLElement, forward: boolean) => HTMLElement|undefined
    // }>}
    containerGroups: [],
    // same order/length as `containers` list
    // references to objects in `containerGroups`, but only those that actually have
    //  tabbable nodes in them
    // NOTE: same order as `containers` and `containerGroups`, but __not necessarily__
    //  the same length
    tabbableGroups: [],
    nodeFocusedBeforeActivation: null,
    mostRecentlyFocusedNode: null,
    active: !1,
    paused: !1,
    // timer ID for when delayInitialFocus is true and initial focus in this trap
    //  has been delayed during activation
    delayInitialFocusTimer: void 0,
    // the most recent KeyboardEvent for the configured nav key (typically [SHIFT+]TAB), if any
    recentNavEvent: void 0
  }, r, i = function(u, c, f) {
    return u && u[c] !== void 0 ? u[c] : s[f || c];
  }, d = function(u, c) {
    var f = typeof (c == null ? void 0 : c.composedPath) == "function" ? c.composedPath() : void 0;
    return a.containerGroups.findIndex(function(S) {
      var y = S.container, O = S.tabbableNodes;
      return y.contains(u) || // fall back to explicit tabbable search which will take into consideration any
      //  web components if the `tabbableOptions.getShadowRoot` option was used for
      //  the trap, enabling shadow DOM support in tabbable (`Node.contains()` doesn't
      //  look inside web components even if open)
      (f == null ? void 0 : f.includes(y)) || O.find(function(V) {
        return V === u;
      });
    });
  }, b = function(u) {
    var c = s[u];
    if (typeof c == "function") {
      for (var f = arguments.length, S = new Array(f > 1 ? f - 1 : 0), y = 1; y < f; y++)
        S[y - 1] = arguments[y];
      c = c.apply(void 0, S);
    }
    if (c === !0 && (c = void 0), !c) {
      if (c === void 0 || c === !1)
        return c;
      throw new Error("`".concat(u, "` was specified but was not a node, or did not return a node"));
    }
    var O = c;
    if (typeof c == "string" && (O = o.querySelector(c), !O))
      throw new Error("`".concat(u, "` as selector refers to no known node"));
    return O;
  }, v = function() {
    var u = b("initialFocus");
    if (u === !1)
      return !1;
    if (u === void 0 || !xe(u, s.tabbableOptions))
      if (d(o.activeElement) >= 0)
        u = o.activeElement;
      else {
        var c = a.tabbableGroups[0], f = c && c.firstTabbableNode;
        u = f || b("fallbackFocus");
      }
    if (!u)
      throw new Error("Your focus-trap needs to have at least one focusable element");
    return u;
  }, g = function() {
    if (a.containerGroups = a.containers.map(function(u) {
      var c = un(u, s.tabbableOptions), f = cn(u, s.tabbableOptions), S = c.length > 0 ? c[0] : void 0, y = c.length > 0 ? c[c.length - 1] : void 0, O = f.find(function(L) {
        return re(L);
      }), V = f.slice().reverse().find(function(L) {
        return re(L);
      }), P = !!c.find(function(L) {
        return te(L) > 0;
      });
      return {
        container: u,
        tabbableNodes: c,
        focusableNodes: f,
        /** True if at least one node with positive `tabindex` was found in this container. */
        posTabIndexesFound: P,
        /** First tabbable node in container, __tabindex__ order; `undefined` if none. */
        firstTabbableNode: S,
        /** Last tabbable node in container, __tabindex__ order; `undefined` if none. */
        lastTabbableNode: y,
        // NOTE: DOM order is NOT NECESSARILY "document position" order, but figuring that out
        //  would require more than just https://developer.mozilla.org/en-US/docs/Web/API/Node/compareDocumentPosition
        //  because that API doesn't work with Shadow DOM as well as it should (@see
        //  https://github.com/whatwg/dom/issues/320) and since this first/last is only needed, so far,
        //  to address an edge case related to positive tabindex support, this seems like a much easier,
        //  "close enough most of the time" alternative for positive tabindexes which should generally
        //  be avoided anyway...
        /** First tabbable node in container, __DOM__ order; `undefined` if none. */
        firstDomTabbableNode: O,
        /** Last tabbable node in container, __DOM__ order; `undefined` if none. */
        lastDomTabbableNode: V,
        /**
         * Finds the __tabbable__ node that follows the given node in the specified direction,
         *  in this container, if any.
         * @param {HTMLElement} node
         * @param {boolean} [forward] True if going in forward tab order; false if going
         *  in reverse.
         * @returns {HTMLElement|undefined} The next tabbable node, if any.
         */
        nextTabbableNode: function(Y) {
          var q = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : !0, G = c.indexOf(Y);
          return G < 0 ? q ? f.slice(f.indexOf(Y) + 1).find(function(Q) {
            return re(Q);
          }) : f.slice(0, f.indexOf(Y)).reverse().find(function(Q) {
            return re(Q);
          }) : c[G + (q ? 1 : -1)];
        }
      };
    }), a.tabbableGroups = a.containerGroups.filter(function(u) {
      return u.tabbableNodes.length > 0;
    }), a.tabbableGroups.length <= 0 && !b("fallbackFocus"))
      throw new Error("Your focus-trap must have at least one container with at least one tabbable node in it at all times");
    if (a.containerGroups.find(function(u) {
      return u.posTabIndexesFound;
    }) && a.containerGroups.length > 1)
      throw new Error("At least one node with a positive tabindex was found in one of your focus-trap's multiple containers. Positive tabindexes are only supported in single-container focus-traps.");
  }, w = function T(u) {
    var c = u.activeElement;
    if (c)
      return c.shadowRoot && c.shadowRoot.activeElement !== null ? T(c.shadowRoot) : c;
  }, D = function T(u) {
    if (u !== !1 && u !== w(document)) {
      if (!u || !u.focus) {
        T(v());
        return;
      }
      u.focus({
        preventScroll: !!s.preventScroll
      }), a.mostRecentlyFocusedNode = u, bn(u) && u.select();
    }
  }, j = function(u) {
    var c = b("setReturnFocus", u);
    return c || (c === !1 ? !1 : u);
  }, R = function(u) {
    var c = u.target, f = u.event, S = u.isBackward, y = S === void 0 ? !1 : S;
    c = c || Ee(f), g();
    var O = null;
    if (a.tabbableGroups.length > 0) {
      var V = d(c, f), P = V >= 0 ? a.containerGroups[V] : void 0;
      if (V < 0)
        y ? O = a.tabbableGroups[a.tabbableGroups.length - 1].lastTabbableNode : O = a.tabbableGroups[0].firstTabbableNode;
      else if (y) {
        var L = lt(a.tabbableGroups, function(ue) {
          var ce = ue.firstTabbableNode;
          return c === ce;
        });
        if (L < 0 && (P.container === c || xe(c, s.tabbableOptions) && !re(c, s.tabbableOptions) && !P.nextTabbableNode(c, !1)) && (L = V), L >= 0) {
          var Y = L === 0 ? a.tabbableGroups.length - 1 : L - 1, q = a.tabbableGroups[Y];
          O = te(c) >= 0 ? q.lastTabbableNode : q.lastDomTabbableNode;
        } else
          be(f) || (O = P.nextTabbableNode(c, !1));
      } else {
        var G = lt(a.tabbableGroups, function(ue) {
          var ce = ue.lastTabbableNode;
          return c === ce;
        });
        if (G < 0 && (P.container === c || xe(c, s.tabbableOptions) && !re(c, s.tabbableOptions) && !P.nextTabbableNode(c)) && (G = V), G >= 0) {
          var Q = G === a.tabbableGroups.length - 1 ? 0 : G + 1, se = a.tabbableGroups[Q];
          O = te(c) >= 0 ? se.firstTabbableNode : se.firstDomTabbableNode;
        } else
          be(f) || (O = P.nextTabbableNode(c));
      }
    } else
      O = b("fallbackFocus");
    return O;
  }, C = function(u) {
    var c = Ee(u);
    if (!(d(c, u) >= 0)) {
      if (de(s.clickOutsideDeactivates, u)) {
        r.deactivate({
          // NOTE: by setting `returnFocus: false`, deactivate() will do nothing,
          //  which will result in the outside click setting focus to the node
          //  that was clicked (and if not focusable, to "nothing"); by setting
          //  `returnFocus: true`, we'll attempt to re-focus the node originally-focused
          //  on activation (or the configured `setReturnFocus` node), whether the
          //  outside click was on a focusable node or not
          returnFocus: s.returnFocusOnDeactivate
        });
        return;
      }
      de(s.allowOutsideClick, u) || u.preventDefault();
    }
  }, $ = function(u) {
    var c = Ee(u), f = d(c, u) >= 0;
    if (f || c instanceof Document)
      f && (a.mostRecentlyFocusedNode = c);
    else {
      u.stopImmediatePropagation();
      var S, y = !0;
      if (a.mostRecentlyFocusedNode)
        if (te(a.mostRecentlyFocusedNode) > 0) {
          var O = d(a.mostRecentlyFocusedNode), V = a.containerGroups[O].tabbableNodes;
          if (V.length > 0) {
            var P = V.findIndex(function(L) {
              return L === a.mostRecentlyFocusedNode;
            });
            P >= 0 && (s.isKeyForward(a.recentNavEvent) ? P + 1 < V.length && (S = V[P + 1], y = !1) : P - 1 >= 0 && (S = V[P - 1], y = !1));
          }
        } else
          a.containerGroups.some(function(L) {
            return L.tabbableNodes.some(function(Y) {
              return te(Y) > 0;
            });
          }) || (y = !1);
      else
        y = !1;
      y && (S = R({
        // move FROM the MRU node, not event-related node (which will be the node that is
        //  outside the trap causing the focus escape we're trying to fix)
        target: a.mostRecentlyFocusedNode,
        isBackward: s.isKeyBackward(a.recentNavEvent)
      })), D(S || a.mostRecentlyFocusedNode || v());
    }
    a.recentNavEvent = void 0;
  }, x = function(u) {
    var c = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : !1;
    a.recentNavEvent = u;
    var f = R({
      event: u,
      isBackward: c
    });
    f && (be(u) && u.preventDefault(), D(f));
  }, K = function(u) {
    if (mn(u) && de(s.escapeDeactivates, u) !== !1) {
      u.preventDefault(), r.deactivate();
      return;
    }
    (s.isKeyForward(u) || s.isKeyBackward(u)) && x(u, s.isKeyBackward(u));
  }, p = function(u) {
    var c = Ee(u);
    d(c, u) >= 0 || de(s.clickOutsideDeactivates, u) || de(s.allowOutsideClick, u) || (u.preventDefault(), u.stopImmediatePropagation());
  }, N = function() {
    if (a.active)
      return at.activateTrap(l, r), a.delayInitialFocusTimer = s.delayInitialFocus ? rt(function() {
        D(v());
      }) : D(v()), o.addEventListener("focusin", $, !0), o.addEventListener("mousedown", C, {
        capture: !0,
        passive: !1
      }), o.addEventListener("touchstart", C, {
        capture: !0,
        passive: !1
      }), o.addEventListener("click", p, {
        capture: !0,
        passive: !1
      }), o.addEventListener("keydown", K, {
        capture: !0,
        passive: !1
      }), r;
  }, h = function() {
    if (a.active)
      return o.removeEventListener("focusin", $, !0), o.removeEventListener("mousedown", C, !0), o.removeEventListener("touchstart", C, !0), o.removeEventListener("click", p, !0), o.removeEventListener("keydown", K, !0), r;
  }, B = function(u) {
    var c = u.some(function(f) {
      var S = Array.from(f.removedNodes);
      return S.some(function(y) {
        return y === a.mostRecentlyFocusedNode;
      });
    });
    c && D(v());
  }, F = typeof window < "u" && "MutationObserver" in window ? new MutationObserver(B) : void 0, _ = function() {
    F && (F.disconnect(), a.active && !a.paused && a.containers.map(function(u) {
      F.observe(u, {
        subtree: !0,
        childList: !0
      });
    }));
  };
  return r = {
    get active() {
      return a.active;
    },
    get paused() {
      return a.paused;
    },
    activate: function(u) {
      if (a.active)
        return this;
      var c = i(u, "onActivate"), f = i(u, "onPostActivate"), S = i(u, "checkCanFocusTrap");
      S || g(), a.active = !0, a.paused = !1, a.nodeFocusedBeforeActivation = o.activeElement, c == null || c();
      var y = function() {
        S && g(), N(), _(), f == null || f();
      };
      return S ? (S(a.containers.concat()).then(y, y), this) : (y(), this);
    },
    deactivate: function(u) {
      if (!a.active)
        return this;
      var c = ot({
        onDeactivate: s.onDeactivate,
        onPostDeactivate: s.onPostDeactivate,
        checkCanReturnFocus: s.checkCanReturnFocus
      }, u);
      clearTimeout(a.delayInitialFocusTimer), a.delayInitialFocusTimer = void 0, h(), a.active = !1, a.paused = !1, _(), at.deactivateTrap(l, r);
      var f = i(c, "onDeactivate"), S = i(c, "onPostDeactivate"), y = i(c, "checkCanReturnFocus"), O = i(c, "returnFocus", "returnFocusOnDeactivate");
      f == null || f();
      var V = function() {
        rt(function() {
          O && D(j(a.nodeFocusedBeforeActivation)), S == null || S();
        });
      };
      return O && y ? (y(j(a.nodeFocusedBeforeActivation)).then(V, V), this) : (V(), this);
    },
    pause: function(u) {
      if (a.paused || !a.active)
        return this;
      var c = i(u, "onPause"), f = i(u, "onPostPause");
      return a.paused = !0, c == null || c(), h(), _(), f == null || f(), this;
    },
    unpause: function(u) {
      if (!a.paused || !a.active)
        return this;
      var c = i(u, "onUnpause"), f = i(u, "onPostUnpause");
      return a.paused = !1, c == null || c(), g(), N(), _(), f == null || f(), this;
    },
    updateContainerElements: function(u) {
      var c = [].concat(u).filter(Boolean);
      return a.containers = c.map(function(f) {
        return typeof f == "string" ? o.querySelector(f) : f;
      }), a.active && g(), _(), this;
    }
  }, r.updateContainerElements(e), r;
};
function Tn(t, e = {}) {
  let n;
  const { immediate: o, ...l } = e, s = A(!1), a = A(!1), r = (v) => n && n.activate(v), i = (v) => n && n.deactivate(v), d = () => {
    n && (n.pause(), a.value = !0);
  }, b = () => {
    n && (n.unpause(), a.value = !1);
  };
  return z(
    () => bt(t),
    (v) => {
      v && (n = wn(v, {
        ...l,
        onActivate() {
          s.value = !0, e.onActivate && e.onActivate();
        },
        onDeactivate() {
          s.value = !1, e.onDeactivate && e.onDeactivate();
        }
      }), o && r());
    },
    { flush: "post" }
  ), ft(() => i()), {
    hasFocus: s,
    isPaused: a,
    activate: r,
    deactivate: i,
    pause: d,
    unpause: b
  };
}
const Sn = (t) => (...e) => {
  t && (t == null || t(...e), t = null);
}, Te = () => {
};
function Ce(t, e, n) {
  return t > n ? n : t < e ? e : t;
}
const _e = (t) => typeof t == "string";
function Ie(t, e) {
  var n;
  const o = ((n = fe(t, e)) == null ? void 0 : n[0]) || e;
  t.push(o);
}
function fe(t, e) {
  const n = t.indexOf(e);
  if (n !== -1)
    return t.splice(n, 1);
}
function St(t) {
  return Object.entries(t);
}
const En = {
  /**
   * @description Set `null | false` to disable teleport.
   * @default `'body'`
   * @example
   * ```js
   * teleportTo: '#modals'
   * ```
   */
  teleportTo: {
    type: [String, null, Boolean, Object],
    default: "body"
  },
  /**
   * @description An uniq name for the open/close a modal via vfm.open/vfm.close APIs.
   * @default `undefined`
   * @example Symbol: `Symbol('MyModal')`
   * @example String: `'AUniqString'`
   * @example Number: `300`
   */
  modalId: {
    type: [String, Number, Symbol],
    default: void 0
  },
  /**
   * @description Display the modal or not.
   * @default `undefined`
   * @example
   * ```js
   * const showModal = ref(false)
   * v-model="showModal"
   * ```
   */
  modelValue: {
    type: Boolean,
    default: void 0
  },
  /**
   * @description Render the modal via `if` or `show`.
   * @default `'if'`
   * @example
   * ```js
   * displayDirective: 'if'
   * ```
   * @example
   * ```js
   * displayDirective: 'show'
   * ```
   */
  displayDirective: {
    type: String,
    default: "if",
    validator: (t) => ["if", "show", "visible"].includes(t)
  },
  /**
   * @description Hide the overlay or not.
   * @default `undefined`
   * @example
   * ```js
   * hideOverlay="true"
   * ```
   */
  hideOverlay: {
    type: Boolean,
    default: void 0
  },
  /**
   * @description Customize the overlay behavior.
   */
  overlayBehavior: {
    type: String,
    default: "auto",
    validator: (t) => ["auto", "persist"].includes(t)
  },
  /**
   * @description Customize the overlay transition.
   * @default `undefined`
   */
  overlayTransition: {
    type: [String, Object],
    default: void 0
  },
  /**
   * @description Customize the content transition.
   * @default `undefined`
   */
  contentTransition: {
    type: [String, Object],
    default: void 0
  },
  /**
   * @description Bind class to vfm__overlay.
   * @default `undefined`
   */
  overlayClass: {
    type: void 0,
    default: void 0
  },
  /**
   * @description Bind class to vfm__content.
   * @default `undefined`
   */
  contentClass: {
    type: void 0,
    default: void 0
  },
  /**
   * @description Bind style to vfm__overlay.
   * @default `undefined`
   */
  overlayStyle: {
    type: [String, Object, Array],
    default: void 0
  },
  /**
   * @description Bind style to vfm__content.
   * @default `undefined`
   */
  contentStyle: {
    type: [String, Object, Array],
    default: void 0
  },
  /**
   * @description Is it allow to close the modal by clicking the overlay.
   * @default `true`
   */
  clickToClose: {
    type: Boolean,
    default: !0
  },
  /**
   * @description Is it allow to close the modal by keypress `esc`.
   * @default `true`
   */
  escToClose: {
    type: Boolean,
    default: !0
  },
  /**
   * @description Is it allow to click outside of the vfm__content when the modal is opened
   * @default `'non-interactive'`
   */
  background: {
    type: String,
    default: "non-interactive",
    validator: (t) => ["interactive", "non-interactive"].includes(t)
  },
  /**
   * @description
   * * Use `{ disabled: true }` to disable the focusTrap.
   * * Checkout the createOptions type here https://github.com/focus-trap/focus-trap for more.
   * @default `{ allowOutsideClick: true }`
   */
  focusTrap: {
    type: [Boolean, Object],
    default: () => ({
      allowOutsideClick: !0
    })
  },
  /**
   * @description Lock body scroll or not when the modal is opened.
   * @default `true`
   */
  lockScroll: {
    type: Boolean,
    default: !0
  },
  /**
   * @description Creates a padding-right when scroll is locked to prevent the page from jumping
   * @default `true`
   */
  reserveScrollBarGap: {
    type: Boolean,
    default: !0
  },
  /**
   * @description Define how to increase the zIndex when there are nested modals
   * @default `({ index }) => 1000 + 2 * index`
   */
  zIndexFn: {
    type: Function,
    default: ({ index: t }) => 1e3 + 2 * t
  },
  /**
   * @description The direction of swiping to close the modal
   * @default `none`
   * @example
   * Set swipeToClose="none" to disable swiping to close
   * ```js
   * swipeToClose="none"
   * ```
   */
  swipeToClose: {
    type: String,
    default: "none",
    validator: (t) => ["none", "up", "right", "down", "left"].includes(t)
  },
  /**
   * @description Threshold for swipe to close
   * @default `0`
   */
  threshold: {
    type: Number,
    default: 0
  },
  /**
   * @description If set `:showSwipeBanner="true"`, only allow clicking `swipe-banner` slot to swipe to close
   * @default `undefined`
   * @example
   * ```js
   * swipeToClose="right"
   * :showSwipeBanner="true"
   * ```
   * ```html
   * <VueFinalModal
   *   ...
   *   swipeToClose="right"
   *   :showSwipeBanner="true"
   * >
   *   <template #swipe-banner>
   *     <div style="position: absolute; height: 100%; top: 0; left: 0; width: 10px;" />
   *   </template>
   *   ...modal content
   * </VueFinalModal>
   * ```
   */
  showSwipeBanner: {
    type: Boolean,
    default: void 0
  },
  /**
   * @description When set `:preventNavigationGestures="true"`, there will be two invisible bars for prevent navigation gestures including swiping back/forward on mobile webkit. For example: Safari mobile.
   * @default `undefined`
   * @example
   * Set preventNavigationGestures="true" to prevent Safari navigation gestures including swiping back/forward.
   * ```js
   * :preventNavigationGestures="true"
   * ```
   */
  preventNavigationGestures: {
    type: Boolean,
    default: void 0
  }
};
function it(t = !1) {
  const e = A(t), n = A(e.value ? 0 : void 0);
  return [e, n, {
    beforeEnter() {
      n.value = 1;
    },
    afterEnter() {
      n.value = 0;
    },
    beforeLeave() {
      n.value = 3;
    },
    afterLeave() {
      n.value = 2;
    }
  }];
}
function Cn(t, e) {
  const { modelValueLocal: n, onEntering: o, onEnter: l, onLeaving: s, onLeave: a } = e, r = A(n.value), [i, d, b] = it(r.value), [v, g, w] = it(r.value), D = U(() => typeof t.contentTransition == "string" ? { name: t.contentTransition, appear: !0 } : { appear: !0, ...t.contentTransition }), j = U(() => typeof t.overlayTransition == "string" ? { name: t.overlayTransition, appear: !0 } : { appear: !0, ...t.overlayTransition }), R = U(
    () => (t.hideOverlay || g.value === 2) && d.value === 2
    /* Leave */
  );
  z(
    R,
    (x) => {
      x && (r.value = !1);
    }
  ), z(d, (x) => {
    if (x === 1) {
      if (!r.value)
        return;
      o == null || o();
    } else if (x === 0) {
      if (!r.value)
        return;
      l == null || l();
    } else
      x === 3 ? s == null || s() : x === 2 && (a == null || a());
  });
  async function C() {
    r.value = !0, await ie(), i.value = !0, v.value = !0;
  }
  function $() {
    i.value = !1, v.value = !1;
  }
  return {
    visible: r,
    contentVisible: i,
    contentListeners: b,
    contentTransition: D,
    overlayVisible: v,
    overlayListeners: w,
    overlayTransition: j,
    enterTransition: C,
    leaveTransition: $
  };
}
function Nn(t, e, n) {
  const { vfmRootEl: o, vfmContentEl: l, visible: s, modelValueLocal: a } = n, r = A();
  function i() {
    s.value && t.escToClose && (a.value = !1);
  }
  function d(v) {
    r.value = v == null ? void 0 : v.target;
  }
  function b() {
    var v;
    r.value === o.value && (t.clickToClose ? a.value = !1 : ((v = l.value) == null || v.focus(), e("clickOutside")));
  }
  return {
    onEsc: i,
    onMouseupRoot: b,
    onMousedown: d
  };
}
function kn(t, e, n) {
  let o = !1;
  const { open: l, close: s } = n, a = A(!1), r = {
    get value() {
      return a.value;
    },
    set value(d) {
      i(d);
    }
  };
  function i(d) {
    (d ? l() : s()) ? (a.value = d, d !== t.modelValue && e("update:modelValue", d)) : (o = !0, e("update:modelValue", !d), ie(() => {
      o = !1;
    }));
  }
  return z(() => t.modelValue, (d) => {
    o || (r.value = !!d);
  }), {
    modelValueLocal: r
  };
}
function On(t, e) {
  if (t.focusTrap === !1)
    return {
      focus() {
      },
      blur() {
      }
    };
  const { focusEl: n } = e, { hasFocus: o, activate: l, deactivate: s } = Tn(n, t.focusTrap);
  function a() {
    requestAnimationFrame(() => {
      l();
    });
  }
  function r() {
    o.value && s();
  }
  return { focus: a, blur: r };
}
let Ye = !1;
if (typeof window < "u") {
  const t = {
    get passive() {
      Ye = !0;
    }
  };
  window.addEventListener("testPassive", null, t), window.removeEventListener("testPassive", null, t);
}
const Et = typeof window < "u" && window.navigator && window.navigator.platform && (/iP(ad|hone|od)/.test(window.navigator.platform) || window.navigator.platform === "MacIntel" && window.navigator.maxTouchPoints > 1);
let J = [], Me = !1, ke = 0, Ct = -1, me, he;
const Fn = (t) => {
  if (!t || t.nodeType !== Node.ELEMENT_NODE)
    return !1;
  const e = window.getComputedStyle(t);
  return ["auto", "scroll"].includes(e.overflowY) && t.scrollHeight > t.clientHeight;
}, Dn = (t, e) => !(t.scrollTop === 0 && e < 0 || t.scrollTop + t.clientHeight + e >= t.scrollHeight && e > 0), Vn = (t) => {
  const e = [];
  for (; t; ) {
    if (e.push(t), t.classList.contains("vfm"))
      return e;
    t = t.parentElement;
  }
  return e;
}, Mn = (t, e) => {
  let n = !1;
  return Vn(t).forEach((o) => {
    Fn(o) && Dn(o, e) && (n = !0);
  }), n;
}, Nt = (t) => J.some(() => Mn(t, -ke)), qe = (t) => {
  const e = t || window.event;
  return Nt(e.target) || e.touches.length > 1 ? !0 : (e.preventDefault && e.preventDefault(), !1);
}, Bn = (t) => {
  if (he === void 0) {
    const e = !!t && t.reserveScrollBarGap === !0, n = window.innerWidth - document.documentElement.clientWidth;
    if (e && n > 0) {
      const o = parseInt(getComputedStyle(document.body).getPropertyValue("padding-right"), 10);
      he = document.body.style.paddingRight, document.body.style.paddingRight = `${o + n}px`;
    }
  }
  me === void 0 && (me = document.body.style.overflow, document.body.style.overflow = "hidden");
}, Ln = () => {
  he !== void 0 && (document.body.style.paddingRight = he, he = void 0), me !== void 0 && (document.body.style.overflow = me, me = void 0);
}, Pn = (t) => t ? t.scrollHeight - t.scrollTop <= t.clientHeight : !1, An = (t, e) => (ke = t.targetTouches[0].clientY - Ct, Nt(t.target) ? !1 : e && e.scrollTop === 0 && ke > 0 || Pn(e) && ke < 0 ? qe(t) : (t.stopPropagation(), !0)), Rn = (t, e) => {
  if (!t) {
    console.error(
      "disableBodyScroll unsuccessful - targetElement must be provided when calling disableBodyScroll on IOS devices."
    );
    return;
  }
  if (J.some((o) => o.targetElement === t))
    return;
  const n = {
    targetElement: t,
    options: e || {}
  };
  J = [...J, n], Et ? (t.ontouchstart = (o) => {
    o.targetTouches.length === 1 && (Ct = o.targetTouches[0].clientY);
  }, t.ontouchmove = (o) => {
    o.targetTouches.length === 1 && An(o, t);
  }, Me || (document.addEventListener("touchmove", qe, Ye ? { passive: !1 } : void 0), Me = !0)) : Bn(e);
}, xn = (t) => {
  if (!t) {
    console.error(
      "enableBodyScroll unsuccessful - targetElement must be provided when calling enableBodyScroll on IOS devices."
    );
    return;
  }
  J = J.filter((e) => e.targetElement !== t), Et ? (t.ontouchstart = null, t.ontouchmove = null, Me && J.length === 0 && (document.removeEventListener("touchmove", qe, Ye ? { passive: !1 } : void 0), Me = !1)) : J.length || Ln();
};
function In(t, e) {
  const { lockScrollEl: n, modelValueLocal: o } = e;
  let l;
  z(n, (r) => {
    r && (l = r);
  }, { immediate: !0 }), z(() => t.lockScroll, (r) => {
    r ? a() : s();
  }), We(() => {
    s();
  });
  function s() {
    l && xn(l);
  }
  function a() {
    o.value && t.lockScroll && l && Rn(l, {
      reserveScrollBarGap: t.reserveScrollBarGap,
      allowTouchMove: (r) => {
        for (; r && r !== document.body; ) {
          if (r.getAttribute("vfm-scroll-lock-ignore") !== null)
            return !0;
          r = r.parentElement;
        }
        return !1;
      }
    });
  }
  return {
    enableBodyScroll: s,
    disableBodyScroll: a
  };
}
function jn(t) {
  const e = A();
  function n(l) {
    var s;
    e.value = (s = t.zIndexFn) == null ? void 0 : s.call(t, { index: l <= -1 ? 0 : l });
  }
  function o() {
    e.value = void 0;
  }
  return {
    zIndex: e,
    refreshZIndex: n,
    resetZIndex: o
  };
}
const je = {
  beforeMount(t, { value: e }, { transition: n }) {
    t._vov = t.style.visibility === "hidden" ? "" : t.style.visibility, n && e ? n.beforeEnter(t) : ve(t, e);
  },
  mounted(t, { value: e }, { transition: n }) {
    n && e && n.enter(t);
  },
  updated(t, { value: e, oldValue: n }, { transition: o }) {
    !e != !n && (o ? e ? (o.beforeEnter(t), ve(t, !0), o.enter(t)) : o.leave(t, () => {
      ve(t, !1);
    }) : ve(t, e));
  },
  beforeUnmount(t, { value: e }) {
    ve(t, e);
  }
};
function ve(t, e) {
  t.style.visibility = e ? t._vov : "hidden";
}
const st = (t) => {
  if (t instanceof MouseEvent) {
    const { clientX: e, clientY: n } = t;
    return { x: e, y: n };
  } else {
    const { clientX: e, clientY: n } = t.targetTouches[0];
    return { x: e, y: n };
  }
};
function $n(t) {
  if (!t)
    return !1;
  let e = !1;
  const n = {
    get passive() {
      return e = !0, !1;
    }
  };
  return t.addEventListener("x", Te, n), t.removeEventListener("x", Te), e;
}
function Kn(t, {
  threshold: e = 0,
  onSwipeStart: n,
  onSwipe: o,
  onSwipeEnd: l,
  passive: s = !0
}) {
  const a = Ke({ x: 0, y: 0 }), r = Ke({ x: 0, y: 0 }), i = U(() => a.x - r.x), d = U(() => a.y - r.y), { max: b, abs: v } = Math, g = U(
    () => b(v(i.value), v(d.value)) >= e
  ), w = A(!1), D = U(() => g.value ? v(i.value) > v(d.value) ? i.value > 0 ? "left" : "right" : d.value > 0 ? "up" : "down" : "none"), j = (h, B) => {
    a.x = h, a.y = B;
  }, R = (h, B) => {
    r.x = h, r.y = B;
  };
  let C, $;
  function x(h) {
    C.capture && !C.passive && h.preventDefault();
    const { x: B, y: F } = st(h);
    j(B, F), R(B, F), n == null || n(h), $ = [
      X(t, "mousemove", K, C),
      X(t, "touchmove", K, C),
      X(t, "mouseup", p, C),
      X(t, "touchend", p, C),
      X(t, "touchcancel", p, C)
    ];
  }
  function K(h) {
    const { x: B, y: F } = st(h);
    R(B, F), !w.value && g.value && (w.value = !0), w.value && (o == null || o(h));
  }
  function p(h) {
    w.value && (l == null || l(h, D.value)), w.value = !1, $.forEach((B) => B());
  }
  let N = [];
  return ct(() => {
    const h = $n(window == null ? void 0 : window.document);
    s ? C = h ? { passive: !0 } : { capture: !1 } : C = h ? { passive: !1, capture: !0 } : { capture: !0 }, N = [
      X(t, "mousedown", x, C),
      X(t, "touchstart", x, C)
    ];
  }), {
    isSwiping: w,
    direction: D,
    coordsStart: a,
    coordsEnd: r,
    lengthX: i,
    lengthY: d,
    stop: () => {
      N.forEach((h) => h()), $.forEach((h) => h());
    }
  };
}
function Gn(t, e) {
  const { vfmContentEl: n, modelValueLocal: o } = e, l = 0.1, s = 300, a = A(), r = U(() => {
    if (!(t.swipeToClose === void 0 || t.swipeToClose === "none"))
      return t.showSwipeBanner ? a.value : n.value;
  }), i = A(0), d = A(!0);
  let b = Te, v = !0, g, w = !1;
  const { lengthX: D, lengthY: j, direction: R, isSwiping: C } = Kn(r, {
    threshold: t.threshold,
    onSwipeStart(p) {
      b = X(document, "selectionchange", () => {
        var N;
        d.value = (N = window.getSelection()) == null ? void 0 : N.isCollapsed;
      }), g = (/* @__PURE__ */ new Date()).getTime(), w = K(p == null ? void 0 : p.target);
    },
    onSwipe() {
      var p, N, h, B;
      if (w && d.value && R.value === t.swipeToClose) {
        if (R.value === "up") {
          const F = Ce(Math.abs(j.value || 0), 0, ((p = r.value) == null ? void 0 : p.offsetHeight) || 0) - (t.threshold || 0);
          i.value = F;
        } else if (R.value === "down") {
          const F = Ce(Math.abs(j.value || 0), 0, ((N = r.value) == null ? void 0 : N.offsetHeight) || 0) - (t.threshold || 0);
          i.value = -F;
        } else if (R.value === "right") {
          const F = Ce(Math.abs(D.value || 0), 0, ((h = r.value) == null ? void 0 : h.offsetWidth) || 0) - (t.threshold || 0);
          i.value = -F;
        } else if (R.value === "left") {
          const F = Ce(Math.abs(D.value || 0), 0, ((B = r.value) == null ? void 0 : B.offsetWidth) || 0) - (t.threshold || 0);
          i.value = F;
        }
      }
    },
    onSwipeEnd(p, N) {
      if (b(), !d.value) {
        d.value = !0;
        return;
      }
      const h = (/* @__PURE__ */ new Date()).getTime(), B = N === t.swipeToClose, F = (() => {
        var T, u;
        if (N === "up" || N === "down")
          return Math.abs((j == null ? void 0 : j.value) || 0) > l * (((T = r.value) == null ? void 0 : T.offsetHeight) || 0);
        if (N === "left" || N === "right")
          return Math.abs((D == null ? void 0 : D.value) || 0) > l * (((u = r.value) == null ? void 0 : u.offsetWidth) || 0);
      })(), _ = h - g <= s;
      if (v && w && B && (F || _)) {
        o.value = !1;
        return;
      }
      i.value = 0;
    }
  }), $ = U(() => {
    if (t.swipeToClose === "none")
      return;
    const p = (() => {
      switch (t.swipeToClose) {
        case "up":
        case "down":
          return "translateY";
        case "left":
        case "right":
          return "translateX";
      }
    })();
    return {
      class: { "vfm-bounce-back": !C.value },
      style: { transform: `${p}(${-i.value}px)` }
    };
  });
  z(
    () => d.value,
    (p) => {
      p || (i.value = 0);
    }
  ), z(
    () => o.value,
    (p) => {
      p && (i.value = 0);
    }
  ), z(
    () => i.value,
    (p, N) => {
      switch (t.swipeToClose) {
        case "down":
        case "right":
          v = p < N;
          break;
        case "up":
        case "left":
          v = p > N;
          break;
      }
    }
  );
  function x(p) {
    t.preventNavigationGestures && p.preventDefault();
  }
  function K(p) {
    const N = p == null ? void 0 : p.tagName;
    if (!N || ["INPUT", "TEXTAREA"].includes(N))
      return !1;
    const h = (() => {
      switch (t.swipeToClose) {
        case "up":
          return (p == null ? void 0 : p.scrollTop) + (p == null ? void 0 : p.clientHeight) === (p == null ? void 0 : p.scrollHeight);
        case "left":
          return (p == null ? void 0 : p.scrollLeft) + (p == null ? void 0 : p.clientWidth) === (p == null ? void 0 : p.scrollWidth);
        case "down":
          return (p == null ? void 0 : p.scrollTop) === 0;
        case "right":
          return (p == null ? void 0 : p.scrollLeft) === 0;
        default:
          return !1;
      }
    })();
    return p === r.value ? h : h && K(p == null ? void 0 : p.parentElement);
  }
  return {
    vfmContentEl: n,
    swipeBannerEl: a,
    bindSwipe: $,
    onTouchStartSwipeBanner: x
  };
}
const kt = Symbol("vfm");
let ne;
const Un = (t) => ne = t, Hn = {
  install: Te,
  modals: [],
  openedModals: [],
  openedModalOverlays: [],
  dynamicModals: [],
  modalsContainers: A([]),
  get: () => {
  },
  toggle: () => {
  },
  open: () => {
  },
  close: () => {
  },
  closeAll: () => Promise.allSettled([])
}, zn = () => He() && xt(kt, Hn) || ne;
function uo() {
  const t = Se([]), e = Se([]), n = Se([]), o = Se([]), l = A([]), s = Ne({
    install(a) {
      a.provide(kt, s), a.config.globalProperties.$vfm = s;
    },
    modals: t,
    openedModals: e,
    openedModalOverlays: n,
    dynamicModals: o,
    modalsContainers: l,
    get(a) {
      return t.find((r) => {
        var i, d;
        return ((d = (i = ye(r)) == null ? void 0 : i.value.modalId) == null ? void 0 : d.value) === a;
      });
    },
    toggle(a, r) {
      var i;
      const d = s.get(a);
      return (i = ye(d)) == null ? void 0 : i.value.toggle(r);
    },
    open(a) {
      return s.toggle(a, !0);
    },
    close(a) {
      return s.toggle(a, !1);
    },
    closeAll() {
      return Promise.allSettled(
        e.reduce((a, r) => {
          const i = ye(r), d = i == null ? void 0 : i.value.toggle(!1);
          return d && a.push(d), a;
        }, [])
      );
    }
  });
  return Un(s), s;
}
function ye(t) {
  var e;
  return (e = t == null ? void 0 : t.exposed) == null ? void 0 : e.modalExposed;
}
const Wn = ze({ inheritAttrs: !1 }), Ot = /* @__PURE__ */ ze({
  ...Wn,
  __name: "VueFinalModal",
  props: En,
  emits: ["update:modelValue", "beforeOpen", "opened", "beforeClose", "closed", "clickOutside"],
  setup(t, { expose: e, emit: n }) {
    const o = t, l = n, s = Mt(), a = He(), { modals: r, openedModals: i, openedModalOverlays: d } = ge(), b = A(), v = A(), { focus: g, blur: w } = On(o, { focusEl: b }), { zIndex: D, refreshZIndex: j, resetZIndex: R } = jn(o), { modelValueLocal: C } = kn(o, l, { open: Y, close: q }), { enableBodyScroll: $, disableBodyScroll: x } = In(o, {
      lockScrollEl: b,
      modelValueLocal: C
    });
    let K = Te;
    const {
      visible: p,
      contentVisible: N,
      contentListeners: h,
      contentTransition: B,
      overlayVisible: F,
      overlayListeners: _,
      overlayTransition: T,
      enterTransition: u,
      leaveTransition: c
    } = Cn(o, {
      modelValueLocal: C,
      onEntering() {
        ie(() => {
          x(), g();
        });
      },
      onEnter() {
        l("opened"), K("opened");
      },
      onLeave() {
        fe(i, a), R(), $(), l("closed"), K("closed");
      }
    }), { onEsc: f, onMouseupRoot: S, onMousedown: y } = Nn(o, l, { vfmRootEl: b, vfmContentEl: v, visible: p, modelValueLocal: C }), {
      swipeBannerEl: O,
      bindSwipe: V,
      onTouchStartSwipeBanner: P
    } = Gn(o, { vfmContentEl: v, modelValueLocal: C }), L = U(() => a ? i.indexOf(a) : -1);
    z([() => o.zIndexFn, L], () => {
      p.value && j(L.value);
    }), ct(() => {
      Ie(r, a);
    }), o.modelValue && (C.value = !0);
    function Y() {
      let m = !1;
      return l("beforeOpen", { stop: () => m = !0 }), m ? !1 : (Ie(i, a), Ie(d, a), G(), u(), !0);
    }
    function q() {
      let m = !1;
      return l("beforeClose", { stop: () => m = !0 }), m ? !1 : (fe(d, a), G(), w(), c(), !0);
    }
    We(() => {
      $(), fe(r, a), fe(i, a), w(), G();
    });
    async function G() {
      await ie();
      const m = d.filter((k) => {
        var M;
        const W = ye(k);
        return (W == null ? void 0 : W.value.overlayBehavior.value) === "auto" && !((M = W == null ? void 0 : W.value.hideOverlay) != null && M.value);
      });
      m.forEach((k, M) => {
        const W = ye(k);
        W != null && W.value && (W.value.overlayVisible.value = M === m.length - 1);
      });
    }
    const Q = Le(() => o.modalId), se = Le(() => o.hideOverlay), ue = Le(() => o.overlayBehavior), ce = U(() => ({
      modalId: Q,
      hideOverlay: se,
      overlayBehavior: ue,
      overlayVisible: F,
      toggle(m) {
        return new Promise((k) => {
          K = Sn((W) => k(W));
          const M = typeof m == "boolean" ? m : !C.value;
          C.value = M;
        });
      }
    }));
    return e({
      modalExposed: ce
    }), (m, k) => (I(), oe(Bt, {
      to: m.teleportTo ? m.teleportTo : void 0,
      disabled: !m.teleportTo
    }, [
      m.displayDirective !== "if" || E(p) ? Pe((I(), ee("div", Z({ key: 0 }, E(s), {
        ref_key: "vfmRootEl",
        ref: b,
        class: ["vfm vfm--fixed vfm--inset", { "vfm--prevent-none": m.background === "interactive" }],
        style: { zIndex: E(D) },
        role: "dialog",
        "aria-modal": "true",
        onKeydown: k[7] || (k[7] = Lt(() => E(f)(), ["esc"])),
        onMouseup: k[8] || (k[8] = Xe(() => E(S)(), ["self"])),
        onMousedown: k[9] || (k[9] = Xe((M) => E(y)(M), ["self"]))
      }), [
        se.value ? le("", !0) : (I(), oe(Ze, Z({ key: 0 }, E(T), Je(E(_))), {
          default: we(() => [
            m.displayDirective !== "if" || E(F) ? Pe((I(), ee("div", {
              key: 0,
              class: Pt(["vfm__overlay vfm--overlay vfm--absolute vfm--inset vfm--prevent-none", m.overlayClass]),
              style: At(m.overlayStyle),
              "aria-hidden": "true"
            }, null, 6)), [
              [Ae, m.displayDirective !== "show" || E(F)],
              [E(je), m.displayDirective !== "visible" || E(F)]
            ]) : le("", !0)
          ]),
          _: 1
        }, 16)),
        $e(Ze, Z(E(B), Je(E(h))), {
          default: we(() => [
            m.displayDirective !== "if" || E(N) ? Pe((I(), ee("div", Z({
              key: 0,
              ref_key: "vfmContentEl",
              ref: v,
              class: ["vfm__content vfm--outline-none", [m.contentClass, { "vfm--prevent-auto": m.background === "interactive" }]],
              style: m.contentStyle,
              tabindex: "0"
            }, E(V), {
              onMousedown: k[6] || (k[6] = () => E(y)())
            }), [
              pe(m.$slots, "default", dt(Rt({ close: q }))),
              m.showSwipeBanner ? (I(), ee("div", {
                key: 0,
                ref_key: "swipeBannerEl",
                ref: O,
                class: "vfm-swipe-banner-container",
                onTouchstart: k[2] || (k[2] = (M) => E(P)(M))
              }, [
                pe(m.$slots, "swipe-banner", {}, () => [
                  H("div", {
                    class: "vfm-swipe-banner-back",
                    onTouchstart: k[0] || (k[0] = (M) => m.swipeToClose === "left" && M.preventDefault())
                  }, null, 32),
                  H("div", {
                    class: "vfm-swipe-banner-forward",
                    onTouchstart: k[1] || (k[1] = (M) => m.swipeToClose === "right" && M.preventDefault())
                  }, null, 32)
                ])
              ], 544)) : !m.showSwipeBanner && m.preventNavigationGestures ? (I(), ee("div", {
                key: 1,
                class: "vfm-swipe-banner-container",
                onTouchstart: k[5] || (k[5] = (M) => E(P)(M))
              }, [
                H("div", {
                  class: "vfm-swipe-banner-back",
                  onTouchstart: k[3] || (k[3] = (M) => m.swipeToClose === "left" && M.preventDefault())
                }, null, 32),
                H("div", {
                  class: "vfm-swipe-banner-forward",
                  onTouchstart: k[4] || (k[4] = (M) => m.swipeToClose === "right" && M.preventDefault())
                }, null, 32)
              ], 32)) : le("", !0)
            ], 16)), [
              [Ae, m.displayDirective !== "show" || E(N)],
              [E(je), m.displayDirective !== "visible" || E(N)]
            ]) : le("", !0)
          ]),
          _: 3
        }, 16)
      ], 16)), [
        [Ae, m.displayDirective !== "show" || E(p)],
        [E(je), m.displayDirective !== "visible" || E(p)]
      ]) : le("", !0)
    ], 8, ["to", "disabled"]));
  }
});
function ge() {
  const t = zn();
  if (!t)
    throw new Error(
      `[Vue Final Modal]: getActiveVfm was called with no active Vfm. Did you forget to install vfm?
	const vfm = createVfm()
	app.use(vfm)
This will fail in production.`
    );
  return t;
}
function ut(t, e = Ot) {
  const { component: n, slots: o, ...l } = t, s = typeof o > "u" ? {} : Object.fromEntries(St(o).map(([a, r]) => _e(r) ? [a, r] : Be(r) ? [a, {
    ...r,
    component: Ne(r.component)
  }] : [a, Ne(r)]));
  return {
    ...l,
    component: Ne(n || e),
    slots: s
  };
}
function co(t) {
  const e = Ke({
    id: Symbol("useModal"),
    modelValue: !!(t != null && t.defaultModelValue),
    resolveOpened: () => {
    },
    resolveClosed: () => {
    },
    attrs: {},
    ...ut(t)
  });
  Wt(() => {
    e != null && e.keepAlive || r();
  }), e.modelValue === !0 && (ne ? ne == null || ne.dynamicModals.push(e) : ie(() => {
    const i = ge();
    i == null || i.dynamicModals.push(e);
  }));
  async function n() {
    let i;
    return ne ? i = ne : (await ie(), i = ge()), e.modelValue ? Promise.resolve("[Vue Final Modal] modal is already opened.") : (r(), e.modelValue = !0, i.dynamicModals.push(e), new Promise((d) => {
      e.resolveOpened = () => d("opened");
    }));
  }
  function o() {
    return e.modelValue ? (e.modelValue = !1, new Promise((i) => {
      e.resolveClosed = () => i("closed");
    })) : Promise.resolve("[Vue Final Modal] modal is already closed.");
  }
  function l(i) {
    const { slots: d, ...b } = ut(i, e.component);
    i.defaultModelValue !== void 0 && (e.defaultModelValue = i.defaultModelValue), (i == null ? void 0 : i.keepAlive) !== void 0 && (e.keepAlive = i == null ? void 0 : i.keepAlive), s(e, b), d && St(d).forEach(([v, g]) => {
      const w = e.slots[v];
      _e(w) ? e.slots[v] = g : Be(w) && Be(g) ? s(w, g) : e.slots[v] = g;
    });
  }
  function s(i, d) {
    d.component && (i.component = d.component), d.attrs && a(i.attrs, d.attrs);
  }
  function a(i, d) {
    return Object.entries(d).forEach(([b, v]) => {
      i[b] = v;
    }), i;
  }
  function r() {
    const i = ge(), d = i.dynamicModals.indexOf(e);
    d !== -1 && i.dynamicModals.splice(d, 1);
  }
  return {
    options: e,
    open: n,
    close: o,
    patchOptions: l,
    destroy: r
  };
}
function vo(t) {
  return t;
}
function Be(t) {
  return typeof t == "object" && t !== null ? "component" in t : !1;
}
const _n = ["innerHTML"], fo = /* @__PURE__ */ ze({
  __name: "ModalsContainer",
  setup(t) {
    const { modalsContainers: e, dynamicModals: n } = ge(), o = Symbol("ModalsContainer"), l = U(() => {
      var r;
      return o === ((r = e.value) == null ? void 0 : r[0]);
    });
    e.value.push(o), We(() => {
      e.value = e.value.filter((r) => r !== o);
    });
    function s(r) {
      var i, d, b;
      (d = (i = n[r]) == null ? void 0 : i.resolveClosed) == null || d.call(i), (b = n[r]) != null && b.keepAlive || n.splice(r, 1);
    }
    function a(r) {
      var i, d;
      (d = (i = n[r]) == null ? void 0 : i.resolveOpened) == null || d.call(i);
    }
    return (r, i) => l.value ? (I(!0), ee(It, { key: 0 }, Qe(E(n), (d, b) => (I(), oe(Re(d.component), Z({
      key: d.id
    }, {
      displayDirective: d != null && d.keepAlive ? "show" : void 0,
      ...typeof d.attrs == "object" ? d.attrs : {}
    }, {
      modelValue: d.modelValue,
      "onUpdate:modelValue": (v) => d.modelValue = v,
      onClosed: () => s(b),
      onOpened: () => a(b)
    }), jt({ _: 2 }, [
      Qe(d.slots, (v, g) => ({
        name: g,
        fn: we(() => [
          E(_e)(v) ? (I(), ee("div", {
            key: 0,
            innerHTML: v
          }, null, 8, _n)) : E(Be)(v) ? (I(), oe(Re(v.component), dt(Z({ key: 1 }, v.attrs)), null, 16)) : (I(), oe(Re(v), { key: 2 }))
        ])
      }))
    ]), 1040, ["modelValue", "onUpdate:modelValue", "onClosed", "onOpened"]))), 128)) : le("", !0);
  }
}), Yn = {
  components: {
    VueFinalModal: Ot
  },
  inheritAttrs: !1,
  props: {
    size: {
      type: String,
      default: ""
    },
    isVerticallyCentered: {
      type: Boolean,
      default: !1
    },
    isScrollableBody: {
      type: Boolean,
      default: !1
    }
  },
  emits: [
    "beforeOpen",
    "opened",
    "beforeClose",
    "closed",
    "update:modelValue"
  ],
  setup(t, { emit: e }) {
    return {
      close: () => e("update:modelValue", !1)
    };
  }
}, qn = { class: "modal-content" }, Xn = { class: "modal-header" }, Zn = { class: "modal-body" };
function Jn(t, e, n, o, l, s) {
  const a = Ge("VueFinalModal");
  return I(), oe(a, Z(t.$attrs, {
    class: "modal d-block",
    "content-class": {
      "modal-dialog": !0,
      "modal-dialog-centered": n.isVerticallyCentered,
      "modal-dialog-scrollable": n.isScrollableBody,
      [`modal-${n.size}`]: n.size
    },
    "content-transition": "vfm-fade",
    "overlay-transition": "vfm-fade",
    onBeforeOpen: e[1] || (e[1] = (r) => t.$emit("beforeOpen")),
    onOpened: e[2] || (e[2] = (r) => t.$emit("opened")),
    onBeforeClose: e[3] || (e[3] = (r) => t.$emit("beforeClose")),
    onClosed: e[4] || (e[4] = (r) => t.$emit("closed")),
    "onUpdate:modelValue": e[5] || (e[5] = (r) => t.$emit("update:modelValue", r))
  }), {
    default: we(() => [
      H("div", qn, [
        pe(t.$slots, "default", { close: o.close }, () => [
          H("div", Xn, [
            pe(t.$slots, "title"),
            H("button", {
              type: "button",
              class: "btn-close",
              "aria-label": "Close",
              onClick: e[0] || (e[0] = (...r) => o.close && o.close(...r))
            })
          ]),
          H("div", Zn, [
            pe(t.$slots, "body", { close: o.close })
          ])
        ])
      ])
    ]),
    _: 3
  }, 16, ["content-class"]);
}
const Qn = /* @__PURE__ */ vt(Yn, [["render", Jn]]), eo = {
  components: {
    VModal: Qn,
    VButton: $t
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    confirmLabel: {
      type: String,
      default: "Confirm"
    },
    cancelLabel: {
      type: String,
      default: "Cancel"
    }
  },
  emits: [
    "confirm"
  ]
}, to = { class: "modal-header" }, no = { class: "modal-title" }, oo = { class: "modal-body" }, ao = { class: "modal-footer justify-content-start" };
function ro(t, e, n, o, l, s) {
  const a = Ge("VButton"), r = Ge("VModal");
  return I(), oe(r, Z(t.$attrs, { "is-vertically-centered": !0 }), {
    default: we(({ close: i }) => [
      H("div", to, [
        H("h5", no, et(n.title), 1)
      ]),
      H("div", oo, et(n.text), 1),
      H("div", ao, [
        $e(a, {
          label: n.confirmLabel,
          onClick: e[0] || (e[0] = (d) => t.$emit("confirm"))
        }, null, 8, ["label"]),
        $e(a, {
          label: n.cancelLabel,
          variant: "secondary",
          class: "px-4",
          onClick: i
        }, null, 8, ["label", "onClick"])
      ])
    ]),
    _: 1
  }, 16);
}
const po = /* @__PURE__ */ vt(eo, [["render", ro]]);
export {
  po as ConfirmModal,
  fo as ModalsContainer,
  Qn as VModal,
  uo as createModal,
  co as useModal,
  vo as useModalSlot,
  ge as useVfm
};
