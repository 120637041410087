import api from './index';

export const getTransactions = (params) => api.get('transactions/me', { params })
  .then((response) => response.data);

export const getAllTransactions = (params) => api.get('transactions/me-all', { params })
  .then((response) => response.data);

export const getTransaction = (id) => api.get(`transactions/${id}`)
  .then((response) => response.data);

export const getAnonTransaction = (id) => api.get(`transactions/anon/${id}`)
  .then((response) => response.data);

export const getTransactionTemplate = (key) => api.get(`transactions/template/${key}`)
  .then((response) => response.data);

export const getTransactionReceipt = (id, receipt) => api.get(`transactions/${id}/receipt/${receipt}`, {
  responseType: 'blob',
}).then((response) => response.data);

export const confirmTransaction = (id, params) => api.post(`transactions/${id}/confirm`, { params })
  .then((response) => response.data);

export const cancelTransaction = (id) => api.get(`transactions/${id}/cancel`)
  .then((response) => response.data);

export const createTransaction = (data) => api.post('transactions', data)
  .then((response) => response.data);

export const createTransactionQuote = (data) => api.post('transactions/quote', data)
  .then((response) => response.data);

export const createTransactionByTemplate = (data) => api.post(`transactions/template/${data.read_key}`, data)
  .then((response) => response.data);
